import BannerDashboardStatsPage from "../pages/Banner-Dashboard-stats-Pages/BannerDashboardStatsPage";

const bannerDashboardRoutes = [
  {
    path: "/banner-dashboard",
    element: <BannerDashboardStatsPage />,
  },
];

export default bannerDashboardRoutes;
