import ProfileCreationPage from "../pages/profile-creation-pages/ProfileCreationPage";

const proufileCreationRoutes = [
  {
    path: "/profile-creation",
    element: <ProfileCreationPage />,
  },
];

export default proufileCreationRoutes;
