import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import LoginPage from "../pages/LoginPage";
import NewPasswordPage from "../pages/NewPasswordPage";



const authRoutes = [
    {
        
        index: true,
        element: <LoginPage />
    },
    { 
        path: "/forgot-password",
        element: <ForgotPasswordPage />
    },
    { 
        path: "/new-password",
        element: <NewPasswordPage />
    },
];

export default authRoutes;