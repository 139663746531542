import ChatPage from "../pages/Chat-Pages/ChatPage";

const chatRoute = [
  {
    path: "/banner-dashboard/chat",
    element: <ChatPage />,
  },
];

export default chatRoute;
