import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { useState } from "react";
import EmailVerificationModal from "./EmailVerificationModal";
import { useDispatch, useSelector } from "react-redux";
import {
  bannerRegister,
  clearMessage,
  countriesList,
  statesByCountry,
  citiesByStates,
} from "../../store/bannerSlice";
import { toast } from "react-toastify";
import { useEffect } from "react";


export default function ProfileCreation() {


  const dispatch = useDispatch();
  const {
    loading,
    message,
    error,
    status,
    country_list,
    states_list,
    cities_list,
  } = useSelector((state) => state.banner);

  const [countryDropDown, setCountryDropDown] = useState(false);
  const [cityOpen, setCityOpen] = useState(false);
  const [stateDropDown, setStateDropDown] = useState(false);
  const [data, setData] = useState({});
  const [selectCountry, setSelectCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectState, setSelectState] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);


  const updateData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCountrySelection = (value) => {
    setSelectCountry(value.name);
    setData({
      ...data,
      country_id: value.id,
    });
    dispatch(statesByCountry(value.id));
    setCountryDropDown(false);
  };

  const handleCitySelection = (value) => {
    setSelectedCity(value.name);
    setData({
      ...data,
      city_id: value.id,
    });
    setCityOpen(false);
  };

  const handleStateSelection = (value) => {
    setSelectState(value.name);
    setData({
      ...data,
      state_id: value.id,
    });
    dispatch(citiesByStates(value.id));
    setStateDropDown(false);
  };

  // form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    const {
      state_id,
      country_id,
      city_id,
      social_security_number,
      password,
      email,
      name,
    } = data;

    if (
      !state_id ||
      !country_id ||
      !city_id ||
      !social_security_number ||
      !password ||
      !email ||
      !name
    ) {
      toast.error("Please fill All Fields ");
    } else {
      dispatch(bannerRegister(data));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (status) {
        toast.success(message);
        document.getElementById("my_modal_3").showModal();

        //   if (message === "OTP has been verified") {
        //     toast.success(message);
        //     navigate("/banner-dashboard")
        // }
      }
      if (error) {
        // error
        const firstKey = Object.values(error)[0];
        toast.error(firstKey[0]);
        console.log(firstKey[0], "error");
      }
      dispatch(clearMessage());
    }
  }, [loading, message, error]);

  // for dropdown open
  const toggleCountryDropDown = () => {
    setCountryDropDown(!countryDropDown);
    setStateDropDown(false);
    setCityOpen(false);
  };

  const toggleStateDropDown = () => {
    setStateDropDown(!stateDropDown);
    setCountryDropDown(false);
    setCityOpen(false);
  };

  const toggleCityDropDown = () => {
    setCityOpen(!cityOpen);
    setCountryDropDown(false);
    setStateDropDown(false);
  };

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    dispatch(countriesList());
  }, []);

  return (
    <div className="flex justify-center w-full mt-14">
      <div className="flex flex-col gap-4 md:gap-16">
        <div className="flex justify-center">
          <img
            className="w-[300px] md:w-[372px] h-[34px]"
            src="assets/icons/progress-1.svg"
            alt=""
          />
        </div>
        <div className="px-4 py-2 md:p-4 flex flex-col-reverse md:flex-row gap-16">
          <div className="flex flex-col gap-4">
            <span className="font-semibold text-sm md:text-base">
              Please complete the form below to post your banner ads on our
              website!
            </span>

            <span className="font-bold text-lg md:text-2xl flex flex-col mt-3">
              <span>Hello Business Owner,</span>
              <span>How about we learn more about you?</span>
            </span>

            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-1 md:gap-4 w-full"
            >
              {/* vendor name */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Enter Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 lg:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                />
              </div>

              {/* store name */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Enter Your Number
                </label>
                <input
                  type="text"
                  name="social_security_number"
                  placeholder="Number"
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 lg:p-3 outline-[#EBF0ED] rounded-lg placeholder:text-xs"
                  onChange={updateData}
                />
              </div>

              {/* Email */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={updateData}
                  className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 lg:p-3 rounded-lg outline-[#EBF0ED] placeholder:text-xs"
                />
              </div>

              {/* Password */}
              <div className="flex flex-col gap-1">
                <label htmlFor="" className="font-semibold text-xs">
                  Password
                </label>
                <span className="flex items-center relative">
                  <input
                    type={passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="******"
                    className="w-full bg-[#FAFAFA] text-xs border border-[#EBF0ED] p-2 lg:p-3 rounded-lg outline-[#EBF0ED] placeholder:text-xs"
                    onChange={updateData}
                  />
                  {passwordVisible ? (
                    <IoEyeOutline
                      className="absolute right-4 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  ) : (
                    <IoEyeOffOutline
                      className="absolute right-4 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  )}
                </span>
              </div>
              <div className="flex flex-col md:flex-row gap-1 md:gap-2 lg:p-3">
                {/* store type */}
                <div className="w-full relative flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="" className="font-semibold text-xs">
                      Country
                    </label>
                    <span className="flex items-center relative">
                      <input
                        type="text"
                        readOnly
                        name="store_type"
                        placeholder="Select Country"
                        value={selectCountry}
                        className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 lg:p-3 rounded-lg outline-none placeholder:text-xs cursor-pointer"
                        onClick={toggleCountryDropDown}
                      />
                      {countryDropDown ? (
                        <RiArrowDropUpLine
                          className="absolute right-4 !w-5 !h-5"
                          onClick={toggleCountryDropDown}
                        />
                      ) : (
                        <RiArrowDropDownLine
                          className="absolute right-4 !w-5 !h-5"
                          onClick={toggleCountryDropDown}
                        />
                      )}
                    </span>
                  </div>

                  {/* dropdown of store type */}
                  {countryDropDown && (
                    <div className="h-24 overflow-y-auto absolute z-50 mt-14 flex flex-col rounded-lg bg-[#FAFAFA] py-3 px-3 mx-2">
                      {country_list?.countries?.map((type) => (
                        <span
                          key={type.id}
                          className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                          onClick={() => handleCountrySelection(type)}
                        >
                          {type.name}
                        </span>
                      ))}
                    </div>
                  )}
                </div>

                {/* select type */}
                <div className="w-full relative flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="" className="font-semibold text-xs">
                      State
                    </label>
                    <span className="flex items-center relative">
                      <input
                        type="text"
                        readOnly
                        name="style_type"
                        placeholder="Select State"
                        value={selectState}
                        className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 lg:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs"
                        onClick={toggleStateDropDown}
                      />
                      {stateDropDown ? (
                        <RiArrowDropUpLine
                          className="absolute right-4 !w-5 !h-5"
                          onClick={toggleStateDropDown}
                        />
                      ) : (
                        <RiArrowDropDownLine
                          className="absolute right-4 !w-5 !h-5"
                          onClick={toggleStateDropDown}
                        />
                      )}
                    </span>
                  </div>

                  {/* dropdown of style type */}
                  {stateDropDown && (
                    <div className="h-24 overflow-y-auto absolute z-50 mt-14 flex flex-col rounded-lg bg-[#FAFAFA] py-3 px-3 mx-2">
                      {states_list?.states?.length > 0 ? (
                        states_list.states.map((state) => (
                          <span
                            key={state.id}
                            className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                            onClick={() => handleStateSelection(state)}
                          >
                            {state.name}
                          </span>
                        ))
                      ) : (
                        <span className="p-1 px-2 text-xs">None</span>
                      )}
                    </div>
                  )}
                </div>
                {/* select city */}
                <div className="w-full relative flex flex-col gap-1">
                  <div className="flex flex-col gap-1">
                    <label htmlFor="" className="font-semibold text-xs">
                      City
                    </label>
                    <span className="flex items-center relative">
                      <input
                        type="text"
                        readOnly
                        name="city"
                        placeholder="Select City"
                        value={selectedCity}
                        className="w-full text-xs bg-[#FAFAFA] border border-[#EBF0ED] p-2 lg:p-3 rounded-lg outline-none cursor-pointer placeholder:text-xs"
                        onClick={toggleCityDropDown}
                      />
                      {cityOpen ? (
                        <RiArrowDropUpLine
                          className="absolute right-4 !w-5 !h-5"
                          onClick={toggleCityDropDown}
                        />
                      ) : (
                        <RiArrowDropDownLine
                          className="absolute right-4 !w-5 !h-5"
                          onClick={toggleCityDropDown}
                        />
                      )}
                    </span>
                  </div>

                  {/* dropdown of city */}
                  {cityOpen && (
                    <div className="h-24 overflow-y-auto absolute z-50 mt-14 flex flex-col rounded-lg bg-[#FAFAFA] py-3 px-3 mx-2">
                      {cities_list?.cities?.length > 0 ? (
                        cities_list?.cities?.map((city) => (
                          <span
                            key={city.id}
                            className="p-1 px-2 text-xs cursor-pointer hover:bg-[#f3f0f0]"
                            onClick={() => handleCitySelection(city)}
                          >
                            {city.name}
                          </span>
                        ))
                      ) : (
                        <span className="p-1 px-2 text-xs">None</span>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* submit button */}
              <button
                disabled={loading}
                type="submit"
                className="bg-[#C19A6B] py-[7px] md:py-[14px] px-9 rounded-lg text-center text-sm md:text-base font-semibold tracking-widest text-white mt-4"
              >
                {!loading ? "Next" : "Loading ..."}
              </button>
            </form>
          </div>

          <div className="hidden md:block">
            <img src="/assets/icons/profile.svg" alt="" />
          </div>
        </div>
      </div>

      {/* modal */}
      <dialog
        id="my_modal_3"
        className="modal p-8 !rounded-lg w-full md:w-[100%]"
      >
        <div className="modal-box">
          <form method="dialog">
            {/* if there is a button in form, it will close the modal */}
            <button className="btn btn-sm btn-circle btn-ghost absolute md:right-8 right-2 top-2 md:top-8 text-[21px]">
              <img
                className="w-3 h-3 md:w-5 md:h-5"
                src="/assets/icons/close-modal.svg"
                alt=""
              />
            </button>
          </form>
          <h3 className="font-semibold text-base md:text-lg">Verify Email</h3>
          <p className="py-2 md:py-4 text-xs md:text-base">
            An email is sent to the address you entered. Enter the code in the
            email to verify your email address.
          </p>
          <EmailVerificationModal email={data.email} />
        </div>
      </dialog>
    </div>
  );
}
