import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bannerPasswordReset, clearMessage } from "../store/bannerSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

export default function NewPasswordPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, message, error, status } = useSelector(
    (state) => state.banner
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (newPassword === "" || confirmNewPassword === "") {
      toast.error("Please fill all the fields");
    } else if (confirmNewPassword != newPassword) {
      toast.error("Password And New Password Not Match");
    } else {
      dispatch(
        bannerPasswordReset({
          email,
          password: newPassword,
          password_confirmation: confirmNewPassword,
          token,
        })
      );
    }
  };

  useEffect(() => {
    if (status) {
      toast.success(message);
      if (message === "Password successfully reset") {
        navigate("/");
        localStorage.clear();
      }
    }
    if (error) {
      toast.error(error);
    }

    dispatch(clearMessage());
  }, [message, error, status]);

  return (
    <div className="h-[100vh] flex justify-center items-center">
      <div
        className="px-14 py-14 bg-white rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-3xl mt-5 mb-2 text-center">
          New Password
        </div>
        <div className="text-sm text-center font-bold tracking-wide">
          Enter your new password and confirm{" "}
        </div>
        <div className="w-[30rem] mt-6">
          <label className="form-control mt-3">
            <div className="label">
              <span className="label-text font-bold tracking-wide">
                Enter New Password
              </span>
            </div>
            <input
              type="password"
              placeholder="• • • • • • • • • • • • • • • •"
              className="input border border-[#EBF0ED] bg-[#FAFAFA] w-full focus:outline-none focus:border-[#EBF0ED]"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </label>

          <label className="form-control mt-3">
            <div className="label">
              <span className="label-text font-bold tracking-wide">
                Confirm New Password
              </span>
            </div>
            <input
              type="password"
              placeholder="• • • • • • • • • • • • • • • •"
              className="input border border-[#EBF0ED] bg-[#FAFAFA] w-full focus:outline-none focus:border-[#EBF0ED]"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </label>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-[#C19A6B] w-full text-[15px] rounded-lg py-3 mt-[1.5rem] text-white font-bold tracking-wider"
          >
            {!loading ? "Confirm" : "Loading"}
          </button>
        </div>
        {/* <div className="text-[#C19A6B] text-end mt-3 font-bold tracking-wide cursor-pointer">
          Forgot Password?
        </div> */}
      </div>
    </div>
  );
}
