import BannerLayout from "../../banner-dashboard-layout/BannerLayout";
import Chat from "../../components/Chat/Chat";

export default function ChatPage() {
  return (
    <BannerLayout>
      <Chat />
    </BannerLayout>
  );
}
