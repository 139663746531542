import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoChevronDown } from "react-icons/go";
import { GoChevronUp } from "react-icons/go";
import BreadCrums from "./Common/BreadCrums";
import Loader from "../Common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  bannerUserDetails,
  bannerUserDetailsUpdate,
  citiesByStates,
  clearMessage,
  countriesList,
  statesByCountry,
} from "../store/bannerSlice";
export default function AccountAndSettings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(null);

  const {
    data,
    loading,
    message,
    error,
    country_list,
    states_list,
    cities_list,
  } = useSelector((state) => state.banner);

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState();
  const [selectedState, setSelectedState] = useState("");
  const [selectedStateId, setSelectedStateId] = useState();
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityId, setSelectedCityId] = useState();

  const [isCountryDropdownVisible, setCountryDropdownVisible] = useState(false);
  const [isStateDropdownVisible, setStateDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setCityDropdownVisible] = useState(false);

  const toggleCountryDropdown = () => {
    setCountryDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
  };
  const toggleStateDropdown = () => {
    setStateDropdownVisible((prev) => !prev);
    setCityDropdownVisible(false);
    setCountryDropdownVisible(false);
  };

  const toggleCityDropdown = () => {
    setCityDropdownVisible((prev) => !prev);
    setCountryDropdownVisible(false);
    setStateDropdownVisible(false);
  };

  const selectCountry = (country) => {
    setSelectedCountry(country.name);
    setSelectedCountryId(country.id);
    setCountryDropdownVisible(false);
    setStateDropdownVisible(false);
    dispatch(statesByCountry(country.id));
  };
  const selectState = (state) => {
    setSelectedState(state.name);
    setSelectedStateId(state.id);
    setCountryDropdownVisible(false);
    setStateDropdownVisible(false);
    setCityDropdownVisible(false);
    dispatch(citiesByStates(state.id));
  };

  const selectCity = (city) => {
    setSelectedCity(city.name);
    setSelectedCityId(city.id);
    setCityDropdownVisible(false);
    setStateDropdownVisible(false);
  };

  useEffect(() => {
    dispatch(bannerUserDetails());
    console.log(data);
  }, [dispatch]);

  useEffect(() => {
    if (!loading &&  data?.data) {
      setUserDetails(data?.data);

      const userData = data?.data;
      const country = country_list?.countries?.find(
        (country) => country.id == userData?.country
      );
      const state = states_list?.states?.find(
        (state) => state.id == userData?.state
      );
      const city = cities_list?.cities?.find(
        (city) => city.id == userData?.city
      );
      setSelectedCountry(country?.name || "");
      setSelectedState(state?.name || "");
      setSelectedCity(city?.name || "");
    }
  }, [data, loading]);

  const handleSubmit = () => {
    const formData = new FormData();
    let hasUpdates = false;

    if (userDetails.name !== data.data.name) {
      formData.append("name", userDetails.name);
      hasUpdates = true;
    }
    if (userDetails.number !== data.data.number) {
      formData.append("number", userDetails.number);
      hasUpdates = true;
    }
    if (userDetails.email !== data.data.email) {
      formData.append("email", userDetails.email);
      hasUpdates = true;
    }
    if (selectedCountryId && selectedCountryId !== data.data.country_id) {
      formData.append("country_id", selectedCountryId);
      hasUpdates = true;
    }
    if (selectedStateId && selectedStateId !== data.data.state_id) {
      formData.append("state_id", selectedStateId);
      hasUpdates = true;
    }
    if (selectedCityId && selectedCityId !== data.data.city_id) {
      formData.append("city_id", selectedCityId);
      hasUpdates = true;
    }

    if (!hasUpdates) {
      toast.info("No changes detected");
      return;
    }

    formData.append("_method", "PUT");

    dispatch(bannerUserDetailsUpdate(formData));
    console.log(formData);
  };

  useEffect(() => {
    if (message) {
      toast.success(message);
      if (message === "Banner updated successfully") {
        navigate("/banner-dashboard");
      }
    }
    if (error) {
      const firstError = Object.values(error)[0];
      toast.error(firstError[0]);
    }
    dispatch(clearMessage());
  }, [message, error, dispatch]);

  useEffect(() => {
    if (data?.data?.state || data?.data?.country) {
      dispatch(countriesList());
      dispatch(statesByCountry(data?.data?.country));
      dispatch(citiesByStates(data?.data?.state));
    }
  }, []);


  if (loading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className="lg:px-3 flex flex-col gap-3">
        {/* head */}
        <div className="flex justify-between ">
          <BreadCrums
            breadCrum={[
              {
                name: "Profile",
                path: "",
              },
            ]}
          />
          <div>
            <button
              disabled={loading}
              onClick={handleSubmit}
              className="text-xs md:text-sm px-5 md:px-9 py-[10px] text-center text-white bg-[#C19A6B] border border-[#EBF0ED] rounded-lg"
            >
              {!loading ? "Update" : "Laoding"}
            </button>
          </div>
        </div>

        {/* Profile Section */}
        <div className="flex flex-col md:flex-row gap-6 w-full">
          {/* Left */}
          <div className="w-full md:w-[35%] flex flex-col gap-4 flex-1">
            <div className=" border border-[#EBF0ED] rounded-lg p-3">
              <div className="flex items-center gap-2 mb-4">
                <div className="relative">
                  <img
                    className="w-14 h-14"
                    src="/assets/images/Mask.png"
                    alt=""
                  />
                  <div className="absolute flex items-center justify-center rounded-full bottom-0 right-0 bg-[#C19A6B] w-4 h-4">
                    <svg
                      width="10"
                      height="8"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.98389 0.204598H5.955C6.29153 0.204598 6.57998 0.428951 6.67613 0.733431L6.85241 1.23021H8.0543C8.61519 1.23021 9.07992 1.69494 9.07992 2.25583V6.35829C9.07992 6.93519 8.61519 7.3839 8.0543 7.3839H1.90061C1.32371 7.3839 0.875 6.93519 0.875 6.35829V2.25583C0.875 1.69494 1.32371 1.23021 1.90061 1.23021H3.08648L3.26276 0.733431C3.35891 0.428951 3.64736 0.204598 3.98389 0.204598ZM4.97746 5.84548C5.8268 5.84548 6.51588 5.15639 6.51588 4.30706C6.51588 3.45772 5.8268 2.76863 4.97746 2.76863C4.1121 2.76863 3.43904 3.45772 3.43904 4.30706C3.43904 5.15639 4.1121 5.84548 4.97746 5.84548Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <h2 className="font-semibold text-base text-[#18120F]">
                    Helena Brauer
                  </h2>
                </div>
              </div>
              <div>
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    FULL NAME :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    Helena Brauer
                  </p>
                </div>
                <hr />
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    MOBILE :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    +92-321-9196978
                  </p>
                </div>
                <hr />
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    E-MAIL :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    example@gmail.com
                  </p>
                </div>
                <hr />
                <div className="flex justify-between my-2">
                  <p className="text-[#6B6B6B] text-[12px] font-semibold">
                    LOCATION :
                  </p>
                  <p className="float-end text-[12px] font-semibold text-[#18120F]">
                    California, USA
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Right */}
          <div className="w-full md:w-[65%] flex flex-col gap-5 flex-2">
            {/* Edit Profile Section */}
            <div>
              <h1 className=" text-[#18120F] text-lg font-semibold">
                Edit Profile
              </h1>
              <div className="w-full border border-[#EBF0ED] rounded-lg p-3">
                {/* Name Inputs */}
                <div className="flex flex-col md:flex-row items-center gap-4 w-full">
                  <div className="w-full">
                    <div className="flex w-full flex-col ">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        FULL NAME
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                        type="text"
                        name="name"
                        placeholder="Enter Your Name"
                        value={userDetails && userDetails.name}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            name: e.target.value,
                          })
                        }
                      />
                    </div>

                    {/* email  */}
                    <div className="flex w-full flex-col ">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        EMAIL ADDRESS
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                        type="text"
                        name="email"
                        placeholder="Enter Your Email"
                        value={userDetails && userDetails.email}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* state  */}
                    <div className="w-full">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        STATE
                      </label>
                      <div className="relative w-full">
                        <input
                          className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                          type="text"
                          value={selectedState}
                          readOnly
                          onClick={toggleStateDropdown}
                          placeholder="Select Your State"
                        />
                        {isStateDropdownVisible ? (
                          <GoChevronUp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                        ) : (
                          <GoChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                        )}

                        {isStateDropdownVisible && (
                          <div className="absolute z-10 bg-white border border-[#EBF0ED] h-24 overflow-y-auto  rounded-lg mt-2 w-full">
                            {states_list?.states?.map((state) => (
                              <div
                                key={state}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => selectState(state)}
                              >
                                {state.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-full">
                    {/* number  */}
                    <div className="flex w-full flex-col ">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        NUMBER
                      </label>
                      <input
                        className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED]   outline-none "
                        type="text"
                        name="number"
                        placeholder="Enter Your Phone Number"
                        value={userDetails && userDetails.number}
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            number: e.target.value,
                          })
                        }
                      />
                    </div>
                    {/* Country Inputs */}
                    <div className="flex items-center gap-2 ">
                      {/* Country */}
                      <div className="w-full">
                        <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                          COUNTRY
                        </label>
                        <div className="relative w-full">
                          <input
                            className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                            type="text"
                            value={selectedCountry}
                            readOnly
                            onClick={toggleCountryDropdown}
                            placeholder="Select Your Country"
                          />
                          {isCountryDropdownVisible ? (
                            <GoChevronUp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                          ) : (
                            <GoChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                          )}

                          {isCountryDropdownVisible && (
                            <div className="absolute z-10 bg-white border border-[#EBF0ED] h-24 overflow-hidden  rounded-lg mt-2 w-full">
                              {country_list?.countries?.map((country) => (
                                <div
                                  key={country}
                                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                  onClick={() => selectCountry(country)}
                                >
                                  {country.name}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* City */}
                    <div className="w-full">
                      <label className="text-[#6B6B6B] text-[12px] ml-1 my-1 font-semibold">
                        CITY
                      </label>
                      <div className="relative w-full">
                        <input
                          className="bg-[#FAFAFA] text-[12px] font-normal text-[#6B6B6B] rounded-lg w-full h-[38px] px-2 border border-[#EBF0ED] outline-none"
                          type="text"
                          value={selectedCity}
                          readOnly
                          onClick={toggleCityDropdown}
                          placeholder="Select Your City"
                        />
                        {isCityDropdownVisible ? (
                          <GoChevronUp className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                        ) : (
                          <GoChevronDown className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                        )}

                        {isCityDropdownVisible && (
                          <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {cities_list?.cities?.map((city) => (
                              <div
                                key={city}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => selectCity(city)}
                              >
                                {city.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
