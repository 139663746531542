import React, { useEffect, useRef, useState } from "react";
import PaymentModal from "./PaymentModal";
import BreadCrums from "../Common/BreadCrums";
import { useDispatch, useSelector } from "react-redux";
import {
  bannerPostAd,
  clearMessage,
  countriesList,
  citiesByCountry,
  categoriesList,
  subCategoriesList,
} from "../../store/bannerSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function PostBannerAd() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const {
    loading,
    message,
    error,
    status,
    country_list,
    cities_list,
    mainCategories,
    subCategories,
  } = useSelector((state) => state.banner);

  const inputRef = useRef(null);
  const [image, setImage] = useState("");

  const [selectCountry2, setSelectCountry2] = useState("");
  const [selectCountry2ID, setSelectCountry2ID] = useState();
  const [selectCity2, setSelectCity2] = useState("");
  const [selectCity2ID, setSelectCity2ID] = useState("");
  const [dropdown2, setDropdown2] = useState(false);
  const [citydropdown2, setCityDropdown2] = useState(false);
  const [dropdownEnabled, setDropdownEnabled] = useState(false); // new state for enabling/disabling

  const toggleDropdown2 = () => {
    if (dropdownEnabled) {
      setDropdown2(!dropdown2);
    }
  };

  const toggleCityDropdown2 = () => {
    if (dropdownEnabled) {
      setCityDropdown2(!citydropdown2);
    }
  };

  const handleCountrySelect2 = (option) => {
    setSelectCountry2(option.name);
    setSelectCountry2ID(option.id);
    setDropdown2(false);
    dispatch(subCategoriesList(selectCountry2ID));
  };

  const handleCitySelect2 = (option) => {
    setSelectCity2(option.name);
    setSelectCity2ID(option.id);
    setCityDropdown2(false);
  };

  const handleRadioChange = (e) => {
    setDropdownEnabled(e.target.value === "disable");
    setDisplayOnHome(e.target.value); // Update state with selected value
  };

  const [offerTitle, setOfferTitle] = useState("");
  const [bannerLink, setBannerLink] = useState("");
  const [storeCountry, setStoreCountry] = useState("");
  const [storeCountryId, setStoreCountryId] = useState();
  const [adCountry, setAdCountry] = useState("");
  const [adCountryId, setAdCountryId] = useState();
  const [storeCity, setStoreCity] = useState("");
  const [storeCityId, setStoreCityId] = useState();
  const [adCity, setAdCity] = useState("");
  const [adCityId, setAdCityId] = useState();
  const [img, setImg] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [price, setPrice] = useState("");
  const [displayOnHome, setDisplayOnHome] = useState("enable"); // New state for radio button

  // State for managing the dropdown's open/close status
  const [dropdown, setDropdown] = useState(false);
  const [citydropdown, setCitydropdown] = useState(false);
  const [citydropdown1, setCitydropdown1] = useState(false);
  const [dropdown1, setDropdown1] = useState(false);



  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImg(file);
    // console.log(file);
    setImage(event.target.files[0]);
  };

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };
  const toggleDropdown1 = () => {
    setDropdown1(!dropdown1);
  };
  const togglecityDropdown = () => {
    setCitydropdown(!citydropdown);
  };
  const togglecityDropdown1 = () => {
    setCitydropdown1(!citydropdown1);
  };

  // store country selection
  const handleCountrySelect = (country) => {
    setStoreCountry(country.name);
    setStoreCountryId(country.id);
    dispatch(citiesByCountry(country.id));
    setDropdown(false);
  };
  const handleCountrySelect1 = (country) => {
    setAdCountry(country.name);
    setAdCountryId(country.id);
    dispatch(citiesByCountry(country.id));
    setDropdown1(false);
  };
  const handleCitySelect = (city) => {
    setStoreCity(city.name);
    setStoreCityId(city.id);
    setCitydropdown(false);
  };
  const handleCitySelect1 = (city) => {
    setAdCity(city.name);
    setAdCityId(city.id);
    setCitydropdown1(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Check if any required field is empty
    if (
      !offerTitle ||
      !storeCountryId ||
      !storeCityId ||
      !adCountryId ||
      !adCityId ||
      !startDate ||
      !endDate ||
      !bannerLink ||
      !img ||
      !price
    ) {
      toast.error("Please fill in all required fields.");
    } else if (displayOnHome !== "enable" && (!selectCountry2 || !selectCity2)) {
      toast.error("Please select category");
    } else {
      const formData = new FormData();
      formData.append("name", offerTitle);
      formData.append("store_country_id", storeCountryId);
      formData.append("store_city_id", storeCityId);
      formData.append("ad_country_id", adCountryId);
      formData.append("ad_city_id", adCityId);
      formData.append("start_date", startDate);
      formData.append("end_date", endDate);
      formData.append("link", bannerLink);
      formData.append("image", img);
      formData.append("price", price);
      
      if (displayOnHome === "enable") {
        formData.append("display_Onhome", 1);
      } else {
        formData.append("display_Onhome", 1);
        formData.append("category_id", selectCountry2ID);
        formData.append("sub_category_id", selectCity2ID);
      }
  
      dispatch(bannerPostAd(formData));
    }
  };
  
  useEffect(() => {
    if (message === "Record created successfully.") {
      toast.success(message);
      navigate("/banner-dashboard/banner-ads");
    }
    if (error) {
      // error
      const firstKey = Object.values(error)[0];
      toast.error(firstKey[0]);
    }
    dispatch(clearMessage());
  }, [error, message]);


  useEffect(() => {
    dispatch(countriesList());
  }, []);

  useEffect(() => {
    dispatch(categoriesList());
  }, []);

  return (
    <>
      <div className="xl:px-4 flex flex-col gap-4">
        {/* Header */}
        <form onSubmit={handleSubmit} action="" encType="multipart/form-data">
          <div className="flex justify-between  items-center">
            <BreadCrums
              breadCrum={[
                {
                  name: "Banner Ad",
                  path: "/banner-dashboard/banner-ads",
                },
                {
                  name: "Post Ad",
                  path: "/banner-dashboard/post-banner-ads",
                },
              ]}
            />
            <button
            type="submit"
              disabled={loading}
              // onClick={() => document.getElementById("my_modal_2").showModal()}
              className="text-white bg-[#C19A6B] text-xs md:text-sm font-semibold py-[5px] md:py-[8px] md:px-7 lg:py-[10px] px-5 lg:px-9 rounded-lg"
            >
              {!loading ? "Confirm" : "Loading "}
            </button>
          </div>

          {/* offers detail */}
          <div className="border border-[#EBF0ED] rounded-xl px-4 py-4 md:py-8 mt-4">
            <div>
              <h2 className="text-base lg:text-lg font-semibold text-[#18120F]">
                Ad Information
              </h2>
            </div>
            {/* first */}
            <div className="flex flex-col md:flex-row gap-5">
              {/* left */}
              <div className="w-full flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                  <h2 className="text-xs font-[500] text-[#18120F] ">
                    Bussiness Name
                  </h2>
                  <input
                    type="text"
                    value={offerTitle}
                    onChange={(e) => {
                      setOfferTitle(e.target.value);
                      // console.log(e.target.value); // Log the value to the console
                    }}
                    className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] outline-none text-xs font-[500] p-2 lg:p-3"
                    placeholder="Black Friday"
                  />
                </div>
                <h2 className="text-xs font-[500] text-[#18120F] ">
                  Store Location
                </h2>
                <div className="flex items-center gap-2 md:gap-4">
                  <div className="w-full">
                    <div className="relative flex flex-col gap-2">
                      <div className="relative w-full">
                        <input
                          className="border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                          type="text"
                          value={storeCountry}
                          readOnly
                          onClick={toggleDropdown}
                          placeholder="Country"
                        />
                        <svg
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                          onClick={toggleDropdown}
                          width="11"
                          height="6"
                          viewBox="0 0 11 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                            fill="#6B6B6B"
                          />
                        </svg>

                        {dropdown && (
                          <div className="h-24 overflow-y-auto absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {country_list?.countries?.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCountrySelect(option)}
                              >
                                {option.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="relative flex flex-col gap-2">
                      <div className="relative w-full">
                        <input
                          className="border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                          type="text"
                          value={storeCity}
                          readOnly
                          onClick={togglecityDropdown}
                          placeholder="City"
                        />
                        <svg
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                          onClick={togglecityDropdown}
                          width="11"
                          height="6"
                          viewBox="0 0 11 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                            fill="#6B6B6B"
                          />
                        </svg>

                        {citydropdown && (
                          <div className="h-24 overflow-y-auto absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {cities_list?.cities?.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCitySelect(option)}
                              >
                                {option.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-[12px] font-[500] text-[#18120F] ">
                    Link
                  </h2>
                  <input
                    value={bannerLink}
                    onChange={(e) => setBannerLink(e.target.value)}
                    type="text"
                    className="w-full rounded-lg border border-[#EBF0ED] bg-[#FAFAFA] outline-none font-[400] text-xs p-2 lg:p-3"
                    placeholder="insert link"
                  />
                </div>
              </div>
              {/* right */}
              <div className="w-full">
                <h2 className="text-[0.75rem] font-[500] text-[#18120F] ">
                  Upload Banner Image
                </h2>
                <div
                  onClick={handleImageClick}
                  className="w-full h-[95%] bg-[#FEFEFE] flex items-center justify-center border border-dashed border-[#E0E0E0] rounded relative"
                >
                  {image ? (
                    <img className="" src={URL.createObjectURL(image)} alt="" />
                  ) : (
                    <img
                      className="w-[76px] lg:w-[96px] h-[112px] lg:h-[138px] p-1"
                      src="/assets/images/input-drag.svg"
                      alt=""
                    />
                  )}
                  <input
                    ref={inputRef}
                    onChange={handleImageChange}
                    type="file"
                    style={{ display: "none" }}
                  />
                  {/* <div className="absolute flex items-center justify-center rounded-full bottom-0 right-0 bg-[#C19A6B] w-4 h-4">
                      <svg
                        width="10"
                        height="8"
                        viewBox="0 0 10 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.98389 0.204598H5.955C6.29153 0.204598 6.57998 0.428951 6.67613 0.733431L6.85241 1.23021H8.0543C8.61519 1.23021 9.07992 1.69494 9.07992 2.25583V6.35829C9.07992 6.93519 8.61519 7.3839 8.0543 7.3839H1.90061C1.32371 7.3839 0.875 6.93519 0.875 6.35829V2.25583C0.875 1.69494 1.32371 1.23021 1.90061 1.23021H3.08648L3.26276 0.733431C3.35891 0.428951 3.64736 0.204598 3.98389 0.204598ZM4.97746 5.84548C5.8268 5.84548 6.51588 5.15639 6.51588 4.30706C6.51588 3.45772 5.8268 2.76863 4.97746 2.76863C4.1121 2.76863 3.43904 3.45772 3.43904 4.30706C3.43904 5.15639 4.1121 5.84548 4.97746 5.84548Z"
                          fill="white"
                        />
                      </svg>
           
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* 2nd */}
          <div className="flex flex-col md:flex-row  gap gap-5 my-6 border border-[#EBF0ED] px-4 py-4 md:py-8 rounded-xl">
            <div className="w-full ">
              <h2 className="text-base lg:text-lg font-semibold text-[#18120F]">
                Ad Details
              </h2>
              <div className="flex flex-col gap-2">
                <h2 className="text-[12px] font-[500] text-[#18120F] ">
                  Ad Location
                </h2>
                <div className="flex items-center gap-4">
                  <div className="w-full">
                    <div className="relative flex flex-col gap-2">
                      <div className="relative w-full">
                        <input
                          className="border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                          type="text"
                          value={adCountry}
                          readOnly
                          onClick={toggleDropdown1}
                          placeholder="Country"
                        />
                        <svg
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                          onClick={toggleDropdown1}
                          width="11"
                          height="6"
                          viewBox="0 0 11 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                            fill="#6B6B6B"
                          />
                        </svg>

                        {dropdown1 && (
                          <div className="h-24 overflow-y-auto absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {country_list?.countries?.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCountrySelect1(option)}
                              >
                                {option.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="relative flex flex-col gap-2">
                      <div className="relative w-full">
                        <input
                          className="border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                          type="text"
                          value={adCity}
                          readOnly
                          onClick={togglecityDropdown1}
                          placeholder="City"
                        />
                        <svg
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                          onClick={togglecityDropdown1}
                          width="11"
                          height="6"
                          viewBox="0 0 11 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                            fill="#6B6B6B"
                          />
                        </svg>

                        {citydropdown1 && (
                          <div className="h-24 overflow-y-auto absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {cities_list?.cities?.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCitySelect1(option)}
                              >
                                {option.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full   gap-4">
                  <div className="w-full">
                    <h2 className="text-[12px] font-[500] text-[#18120F] ">
                      Start Date
                    </h2>

                    <input
                      className="border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none "
                      type="date"
                      name=""
                      id=""
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <h2 className="text-[12px] font-[500] text-[#18120F] ">
                      End Date
                    </h2>
                    <input
                      className="border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none "
                      type="date"
                      name=""
                      id=""
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />{" "}
                  </div>
                </div>

                <div className="flex flex-col gap-1">
                  <h2 className="text-[12px] font-[500] text-[#18120F] ">
                    Price
                  </h2>
                  <input
                    type="text"
                    className="border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                    placeholder="$100"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col gap-2 md:gap-4 md:mt-12">
              <h2 className="text-[#18120F] text-xs font-semibold">
                Display on Home
              </h2>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="dropdownControl"
                    value="enable"
                    onChange={handleRadioChange}
                    defaultChecked={displayOnHome === "enable"}
                  />
                  <label className="text-xs md:text-base">Yes</label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    name="dropdownControl"
                    value="disable"
                    onChange={handleRadioChange}
                    defaultChecked={displayOnHome === "disable"}
                  />
                  <label className="text-xs md:text-base">No</label>
                </div>
              </div>

              {/* category */}
              <div>
                <h2 className="text-[12px] font-[500] text-[#18120F]">
                  Category
                </h2>

                <div className="flex items-center gap-4">
                  <div className="w-full">
                    <div className="relative flex flex-col gap-2">
                      <div className="relative w-full">
                        <input
                          className={`${
                            dropdownEnabled
                              ? "border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                              : "border border-[#EBF0ED] opacity-50 bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                          }`}
                          type="text"
                          value={selectCountry2}
                          readOnly
                          onClick={toggleDropdown2}
                          placeholder="Main Category"
                          disabled={!dropdownEnabled} // disabled condition
                        />
                        <svg
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                          onClick={toggleDropdown2}
                          width="11"
                          height="6"
                          viewBox="0 0 11 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                            fill="#6B6B6B"
                          />
                        </svg>

                        {dropdown2 && (
                          <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {mainCategories?.categories?.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCountrySelect2(option)}
                              >
                                {option.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="relative flex flex-col gap-2">
                      <div className="relative w-full">
                        <input
                          className={`${
                            dropdownEnabled
                              ? "border border-[#EBF0ED] bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                              : "border border-[#EBF0ED] opacity-50 bg-[#FAFAFA] rounded-lg w-full text-xs p-2 lg:p-3 outline-none"
                          }`}
                          type="text"
                          value={selectCity2}
                          readOnly
                          onClick={toggleCityDropdown2}
                          placeholder="Sub Category"
                          disabled={!dropdownEnabled} // disabled condition
                        />
                        <svg
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500"
                          onClick={toggleCityDropdown2}
                          width="11"
                          height="6"
                          viewBox="0 0 11 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2233 1.00307C10.0456 0.84786 9.80514 0.760742 9.55453 0.760742C9.30391 0.760742 9.06348 0.84786 8.88575 1.00307L5.48019 3.95307L2.12207 1.00307C1.94433 0.84786 1.7039 0.760742 1.45329 0.760742C1.20267 0.760742 0.962244 0.84786 0.784507 1.00307C0.695594 1.08054 0.625022 1.17271 0.576862 1.27426C0.528701 1.3758 0.503906 1.48473 0.503906 1.59474C0.503906 1.70475 0.528701 1.81367 0.576862 1.91522C0.625022 2.01677 0.695594 2.10893 0.784507 2.1864L4.80667 5.71974C4.89485 5.79784 4.99977 5.85984 5.11537 5.90214C5.23097 5.94445 5.35496 5.96623 5.48019 5.96623C5.60542 5.96623 5.72941 5.94445 5.84501 5.90214C5.96061 5.85984 6.06553 5.79784 6.15371 5.71974L10.2233 2.1864C10.3122 2.10893 10.3828 2.01677 10.4309 1.91522C10.4791 1.81367 10.5039 1.70475 10.5039 1.59474C10.5039 1.48473 10.4791 1.3758 10.4309 1.27426C10.3828 1.17271 10.3122 1.08054 10.2233 1.00307Z"
                            fill="#6B6B6B"
                          />
                        </svg>

                        {citydropdown2 && (
                          <div className="absolute z-10 bg-white border border-[#EBF0ED] rounded-lg mt-2 w-full">
                            {subCategories?.subcategories?.map((option) => (
                              <div
                                key={option.id}
                                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleCitySelect2(option)}
                              >
                                {option.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <PaymentModal />
    </>
  );
}
