import BannerLayout from "../../banner-dashboard-layout/BannerLayout";
import BannerStats from "../../components/Banner-Ads/BannerStats";

export default function BannerStatsPage() {
  return (
    <BannerLayout>
      <BannerStats />
    </BannerLayout>
  );
}
