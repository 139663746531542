import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import BreadCrums from "../../components/Common/BreadCrums";
import {
  bannerAdDelete,
  bannerAdList,
  bannerAdListSearch,
  clearMessage,
} from "../../store/bannerSlice";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import DeletePopup from "../../Common/DeletePopup";
import { imagePath } from "../../functions/URLString";
import { toast } from "react-toastify";

export default function BannerAdsList() {
  const dispatch = useDispatch();

  const [activeRole, setActiveRole] = useState("Active");
  const [search, setSearch] = useState("");

  const { data, loading, message } = useSelector((state) => state.banner);

    // State for delete popup visibility
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedBrandId, setSelectedBrandId] = useState(null);

  useEffect(() => {
    dispatch(bannerAdList({ status: "accepted" }));
  }, [dispatch]);

  useEffect(() => {
    if (search) {
      dispatch(bannerAdListSearch(search));
    } else {
      if (activeRole === "Active")
        dispatch(bannerAdList({ status: "accepted" }));
      else if (activeRole === "Upcoming") {
        dispatch(bannerAdList({ status: "pending" }));
      }
      else if (activeRole === "Ended"){
        dispatch(bannerAdList({ status: "rejected" }));
      }
    }
  }, [search, dispatch]);

  const handleRoleChange = (role) => {
    setActiveRole(role);
  };


  useEffect(() => {
    if (message) {
      toast.success(message);
    }

    dispatch(clearMessage());
  }, [message]);

    // Handle delete button click
    const handleDeleteClick = (id) => {
      setSelectedBrandId(id);
      setShowDeletePopup(true); // Show the delete popup
    };

    const handleDeleteConfirmation = () => {
      dispatch(bannerAdDelete(selectedBrandId)).then(() => {
        if (activeRole === "Active")
          dispatch(bannerAdList({ status: "accepted" }));
        else if (activeRole === "Upcoming") {
          dispatch(bannerAdList({ status: "pending" }));
        }
        else if (activeRole === "Ended"){
          dispatch(bannerAdList({ status: "rejected" }));
        }
      });
      setShowDeletePopup(false); // Hide the popup after confirmation
    };
      // Handle delete cancellation in popup
  const handleDeleteCancel = () => {
    setShowDeletePopup(false); // Hide the popup on cancel
  };

  return (
    <div className="xl:px-4 flex flex-col gap-4 bg-[]">
      {/* Header */}
      <div className="flex justify-between  items-center">
        <BreadCrums
          breadCrum={[
            {
              name: "Banner Ad",
              path: "/banner-dashboard/banner-ads",
            },
            {
              name: "Banner Ad List",
              path: "/banner-dashboard/banner-ads",
            },
          ]}
        />

        <Link
          to="/banner-dashboard/post-banner-ads"
          className="text-white bg-[#C19A6B] text-xs md:text-sm font-semibold py-[7px] md:py-[10px] px-5 md:px-9 rounded-lg"
        >
          Post Ad
        </Link>
      </div>

      <div className="relative flex items-center w-full ">
        <img
          src="/assets/icons/manage-products/search.svg"
          alt=""
          className="absolute left-3 text-[#C19A6B]"
        />

        <input
          className="w-full py-2 md:py-3 pl-10 rounded-lg bg-white border border-[#EBF0ED] focus:outline-none text-[#6B6B6B] font-[500] text-[14px]"
          type="search"
          placeholder="Search User ..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      {/* filter */}
      <div className="flex gap-6 text-xs md:text-sm md:py-3">
        <NavLink
          onClick={() => {
            handleRoleChange("Active");
            dispatch(bannerAdList({ status: "accepted" }));
          }}
          className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
            activeRole === "Active" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          ACTIVE
          <span
            className={` font-semibold px-1 md:px-2 py-1 bg-[#FAFAFA] border rounded-[5.75px] ${
              activeRole === "Active" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            ({!loading && data ? data?.accepted : 0})
          </span>
        </NavLink>
        <NavLink
          onClick={() => {
            handleRoleChange("Upcoming");
            dispatch(bannerAdList({ status: "pending" }));
          }}
          className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
            activeRole === "Upcoming"
              ? "text-[#C19A6B]"
              : "hover:text-[#C19A6B]"
          }`}
        >
          {" "}
          UPCOMING
          <span
            className={` font-semibold px-1 md:px-2 py-1 bg-[#FAFAFA] border rounded-[5.75px] ${
              activeRole === "Upcoming"
                ? "border-[#C19A6B]"
                : "border-[#EBF0ED]"
            }`}
          >
            ({!loading && data ? data?.pending : 0})
          </span>
        </NavLink>
        <NavLink
          onClick={() => {
            handleRoleChange("Ended");
            dispatch(bannerAdList({ status: "rejected" }));
          }}
          className={`flex items-center font-semibold  gap-2 text-[#828282] uppercase ${
            activeRole === "Ended" ? "text-[#C19A6B]" : "hover:text-[#C19A6B]"
          }`}
        >
          ENDED
          <span
            className={` font-semibold px-1 md:px-2 py-1 bg-[#FAFAFA] border rounded-[5.75px] ${
              activeRole === "Ended" ? "border-[#C19A6B]" : "border-[#EBF0ED]"
            }`}
          >
            ({!loading && data ? data?.rejected : 0})
          </span>
        </NavLink>
      </div>

      {/* table */}
      <div className="flex justify-center border-gray-500 overflow-auto ">
        <div className="bg-white w-full rounded-lg border border-gray-300 overflow-hidden overflow-x-auto">
          <div className="min-w-[668px] md:min-w-[1024px] lg:w-full">
            {/* Table Header */}
            <div className="flex  items-center p-2 md:p-4 text-[10px] md:text-sm border-b text-[#6B6B6B] font-semibold  border-gray-300 justify-between">
              <span className="w-[20%] text-left">LOCAL AD</span>
              <span className="w-[15%] text-left">STORE LOCATION</span>
              <span className="w-[15%] text-left text-nowrap">AD LOCATION</span>
              <span className="w-[10%] text-left text-nowrap">AD PRICE</span>
              <span className="w-[25%] text-left">TIME</span>
              <span className="w-[10%] text-left">ACTION</span>
            </div>

            {/* Table Body */}
            {loading ? (
              <div className="flex justify-center py-4">
                <Loader />
              </div>
            ) : data?.data?.length > 0 ? (
              data?.data?.map((ad) => (
                <div
                  key={ad.id}
                  className="flex text-[10px] md:text-sm items-center justify-between text-[#18120F] p-2 md:p-4 border-b"
                >
                  <div className=" w-[150px] text-left">
                    <div className="flex items-center gap-2">
                      <img
                        className="rounded-lg w-[72px] h-[60px]"
                        src={imagePath + ad.image}
                        alt=""
                      />
                      <span className="text-nowrap">{ad.name}</span>
                    </div>
                  </div>

                  <span className="w-[100px] text-left">{ad.store_city}</span>
                  <span className="w-[100px] text-left">{ad.ad_city}</span>
                  <span className="w-[50px] text-left">${ad.price}</span>
                  <div className="w-[250px] text-left flex items-center gap-2 text-nowrap">
                    <span className="border border-[#15CA32] bg-[#F6FFF7] text-[#15CA32] py-1 px-3 text-sm font-[600] rounded-xl">
                      {ad.start_date}
                    </span>{" "}
                    to{" "}
                    <span className="border border-[#E2362F] bg-[#FFF3F4] text-[#E2362F] py-1 px-3 text-sm font-[600] rounded-xl">
                      {ad.end_date}
                    </span>
                  </div>
                  <div className="w-[100px] text-left flex gap-2">
                    {ad.status === "Pending" ? (
                      <></>
                    ) : (
                      <>
                        <div to="/banner-dashboard/banner-ads/banner-stats">
                          <img src="/assets/icons/details.svg" alt="" />
                        </div>
                      </>
                    )}
                    {ad.status === "Rejected" ? (
                      <></>
                    ) : (
                      <>
                        <div
                          className="cursor-pointer"
                          onClick={() => handleDeleteClick(ad.id)}
                        >
                          <img src="/assets/icons/block.svg" alt="" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center font-bold tracking-wide">
                No {activeRole} Banner Found
              </div>
            )}
          </div>
        </div>
      </div>
      {showDeletePopup && (
        <DeletePopup
          isVisible={showDeletePopup}
          onDeleteConfirmation={handleDeleteConfirmation} // Directly pass the function
          onDeleteCancel={handleDeleteCancel}
        />
      )}
    </div>


  );
}
