import React from "react";
import BannerLayout from "../../banner-dashboard-layout/BannerLayout";
import PostBannerAd from "../../components/Banner-Ads/PostBannerAd";

export default function PostBannerAdPage() {
  return (
    <div>
      <BannerLayout>
        <PostBannerAd />
      </BannerLayout>
    </div>
  );
}
