import { useRef, useState } from "react";
import "./email-verification.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { bannerVerifyOTP, clearMessage } from "../../store/bannerSlice";
import { useEffect } from "react";
export default function EmailVerificationModal({ email }) {
  const dispatch = useDispatch();
  const { loading, message, status, error } = useSelector(
    (state) => state.banner
  );

  const [inputs, setInputs] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const navigate = useNavigate();

  const handleChange = (index, event) => {
    const value = event.target.value;

    if (value.length > 1) return;

    const newInputs = [...inputs];
    newInputs[index] = value;

    setInputs(newInputs);

    if (value && index < inputs.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const OTP = inputs.join("");

    if (
      inputs[0] === "" ||
      inputs[1] === "" ||
      inputs[2] === "" ||
      inputs[3] === "" ||
      inputs[4] === "" ||
      inputs[5] === ""
    ) {
      toast.error("Please Enter 6 Digits OTP");
    } else {
      dispatch(bannerVerifyOTP({ otp: OTP, email: email }));
    }
  };

  useEffect(() => {
    // if (!loading) {
    console.log(message);
    if (message === "OTP has been verified") {
      toast.success(message);
      navigate("/banner-dashboard");
    }
    if (error) {
      toast.error(message);
    }
    // if (error) {
    //   const firstKey = Object.values(error)[0];
    //   toast.error(firstKey[0]);
    //   console.log(firstKey[0], "error");
    // }
    dispatch(clearMessage());
    // }
  }, [message,error]);

  return (
    <div className="flex justify-center mt-3">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col gap-8 items-center"
      >
        <div className="flex items-center gap-4">
          {inputs.map((value, index) => (
            <span key={index} className="email">
              <input
                type="number"
                className="w-7 md:w-10 outline-none border-b-2 border-black pb-2 text-center text-black text-lg font-semibold "
                value={value}
                maxLength={1}
                onChange={(event) => handleChange(index, event)}
                ref={(input) => (inputRefs.current[index] = input)}
              />
            </span>
          ))}
        </div>

        {/* buttons  */}
        <div className="flex gap-2 md:gap-4">
          <button className="py-[8px] md:py-[10px] text-nowrap px-4 md:px-9 rounded-lg border border-[#C19A6B] text-[#C19A6B] text-xs md:text-sm font-semibold tracking-wider">
            Change Email
          </button>
          <button
            type="submit"
            className="py-[10px] px-[30px] md:px-[61px] text-nowrap rounded-lg bg-[#C19A6B]  text-white text-xs md:text-sm font-semibold tracking-wider"
          >
            {!loading ? "Verify" : "Loading"}
          </button>
        </div>
      </form>
    </div>
  );
}
