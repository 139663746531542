import {
    createAsyncThunk,
    createSlice
} from "@reduxjs/toolkit";
import axios from "../functions/axios";
import {
    BANNER_AD_DELETE,

    BANNER_AD_LISTS,
    BANNER_AD_LISTS_SEARCH,
    BANNER_AD_POST,

    BANNER_FORGOT_PASSWORD,
    BANNER_FORGOT_PASSWORD_VERIFY_OTP,
    BANNER_LOGIN,
    BANNER_PASSWORD_RESET,
    BANNER_REGISTER,
    BANNER_UPDATE_USER_DETAILS,
    BANNER_USER_DETAILS,
    BANNER_VERIFY_OTP,
    CITIES_BY_COUNTRY,
    CITIES_BY_STATES,
    COUNTRY_LIST,
    STATES_BY_COUNTRY
} from "./URLConstant";

const initialState = {
    data: [],
    country_list: null,
    states_list: null,
    cities_list: null,
    mainCategories: null,
    subCategories: null,
    loading: false,
    message: null,
    error: null,
    status: null,
    token: localStorage.getItem("banner_token"),
    role: localStorage.getItem("role"),
    signout:false,
};





//   *********** Banner Register Async Thuks Start Here    ***********

// Async thunk for banner registration
export const bannerRegister = createAsyncThunk("bannerRegister", async (banner_info, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_REGISTER, banner_info);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
// banner verify otp
export const bannerVerifyOTP = createAsyncThunk("bannerVerifyOTP", async (otp, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_VERIFY_OTP, otp);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

//   *********** Banner Register Async Thuks End Here    ***********


//   *********** Banner Auth Async Thuks Start Here    ***********

// banner login
export const bannerLogin = createAsyncThunk("bannerLogin", async (login_details, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_LOGIN, login_details);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
// banner forgot password
export const bannerForgotPassword = createAsyncThunk("bannerForgotPassword", async (email, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_FORGOT_PASSWORD, email);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
// banner verify otp
export const bannerForgotPasswordVerifyOTP = createAsyncThunk("bannerForgotPasswordVerifyOTP", async (otp, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_FORGOT_PASSWORD_VERIFY_OTP, otp);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// banner password reset
export const bannerPasswordReset = createAsyncThunk("bannerPasswordReset", async (password_detail, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_PASSWORD_RESET, password_detail);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

//   *********** Banner Auth Async Thuks End Here    ***********


//   *********** Banner-AD Async Thunks Start here ***********

// banner creation
export const bannerPostAd = createAsyncThunk("bannerPostAd", async (ad_details, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_AD_POST, ad_details);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});

// banner ad list 
export const bannerAdList = createAsyncThunk(
    "/bannerAdList",
    async ({ page, status }) => {
      if (!page && !status) {
        const res = await axios.get(BANNER_AD_LISTS);
        return res.data;
      } else if (page && !status) {
        const res = await axios.get(`${BANNER_AD_LISTS}?page=` + page);
        return res.data;
      } else if (!page && status) {
        const res = await axios.get(`${BANNER_AD_LISTS}?status=` + status);
        return res.data;
      } else if (page && status) {
        const res = await axios.get(
          `${BANNER_AD_LISTS}?status=` + status + "&page=" + page
        );
        return res.data;
      }
    }
  );

// banner ad delete 
export const bannerAdDelete = createAsyncThunk("bannerAdDelete", async (id, {
    rejectWithValue
  }) => {
    try {
      const res = await axios.delete(BANNER_AD_DELETE + id);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

  // User-list-search
export const bannerAdListSearch = createAsyncThunk("bannerAdListSearch", async (query, {
    rejectWithValue
  }) => {
    try {
      // const queryParam = query.includes('@') ? `email=${encodeURIComponent(query)}` : `name=${encodeURIComponent(query)}`;
      const queryParam = `search=${encodeURIComponent(query)}`
      // Build the URL with the determined query parameter
      const res = await axios.get(`${BANNER_AD_LISTS_SEARCH}?${queryParam}`);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  });

  // *********** banner profile async thunk start here ***********

   // banner account and setting
export const bannerUserDetails = createAsyncThunk("bannerUserDetails", async (id, {
    rejectWithValue
}) => {
    try {
        const res = await axios.get(BANNER_USER_DETAILS);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});


   // banner account and setting
export const bannerUserDetailsUpdate = createAsyncThunk("bannerUserDetailsUpdate", async (Update_Details, {
    rejectWithValue
}) => {
    try {
        const res = await axios.post(BANNER_UPDATE_USER_DETAILS, Update_Details);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
});
// *********** banner profile async thunk end here ***********
 
// countries list
export const countriesList = createAsyncThunk("/countriesList", async (id, {
    rejectWithValue
  }) => {
    try {
        const res = await axios.get(COUNTRY_LIST);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
  });
//   states list by country id
  export const statesByCountry = createAsyncThunk("/statesByCountry", async (id, {
    rejectWithValue
  }) => {
    try {
        const res = await axios.get(STATES_BY_COUNTRY+id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
  });
//   cities list by state id
export const citiesByStates = createAsyncThunk("/citiesByStates", async (id, {
    rejectWithValue
  }) => {
    try {
        const res = await axios.get(CITIES_BY_STATES+id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
  });
//   cities list by country
export const citiesByCountry = createAsyncThunk("/citiesByCountry", async (id, {
    rejectWithValue
  }) => {
    try {
        const res = await axios.get(CITIES_BY_COUNTRY+id);
        return res.data;
    } catch (err) {
        return rejectWithValue(err.response.data);
    }
  });

//   main categories
export const categoriesList = createAsyncThunk(
    "/categoriesList",
    async () => {
      const res = await axios.get("public/list-main-category");
      return res.data;
    }
  );
export const subCategoriesList = createAsyncThunk(
    "/subCategoriesList",
    async (id) => {
      const res = await axios.get(`public/list-sub-category/${id}`);
      return res.data;
    }
  );

//   *********** Banner-AD Async Thunks End here ***********

const bannerSlice = createSlice({
    name: "banner",
    initialState,
    reducers: {
        clearMessage: (state) => {
            state.message = null;
            state.error = null;
            state.status = null;
        },
        resetSignout: (state) => {
            state.signout = false;
          
          },
        signoutUser: (state) => {
            localStorage.clear();
            state.token = null;
            state.role = null;
            state.signout = true;
         }
    },
    extraReducers: (builder) => {


//   *********** Banner Registration Add-Cases Start Here    ***********

        // banner registration
        builder
            .addCase(bannerRegister.pending, (state) => {
                state.loading = true;
            })
            .addCase(bannerRegister.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                if (!payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.status;
                } else {
                    console.log(payload)
                }
            })
            .addCase(bannerRegister.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.errors || "banner Registration Failed";
            });

        // banner verify otp
        builder
            .addCase(bannerVerifyOTP.pending, (state) => {
                state.loading = true;
            })
            .addCase(bannerVerifyOTP.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                if (!payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.success;
                    console.log(payload)
                } else {
                    state.message = payload.message;
                }
            })
            .addCase(bannerVerifyOTP.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.errors || "banner Registration Failed";
                console.log(action.payload.errors)
            });

//   *********** Banner Registration Add-Cases End Here    ***********


//   *********** Banner Auth Add-Cases Start Here    ***********

        // banner login
        builder
            .addCase(bannerLogin.pending, (state) => {
                state.loading = true;
            })
            .addCase(bannerLogin.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                if (payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.success;
                    state.token = payload.token
                    state.role = payload.user.role
                    if (payload.user.role === "3") {

                        localStorage.setItem('banner_token', payload.token)
                        localStorage.setItem('role', payload.user.role)
                    }

                    console.log(payload)
                } else {
                    console.log(payload)
                    state.message = payload.message;
                }
            })
            .addCase(bannerLogin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message
                console.log(action.payload)

                const error = action.payload?.message;

                if (error && error.includes('Unauthorized')) {
                    state.signout = true;
                  }
            });

        // banner forgot password
        builder
            .addCase(bannerForgotPassword.pending, (state) => {
                state.loading = true;
            })
            .addCase(bannerForgotPassword.fulfilled, (state, {
                payload
            }) => {
                state.loading = false;
                if (payload.success) {
                    state.message = payload.message;
                    state.data = payload;
                    state.status = payload.success;

                    console.log(payload)
                } else {
                    console.log(payload)
                    state.message = payload.message;
                }
            })
            .addCase(bannerForgotPassword.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message
                console.log(action.payload)
            });

        // banner forgot password verify otp
        builder
        .addCase(bannerForgotPasswordVerifyOTP.pending, (state) => {
            state.loading = true;
        })
        .addCase(bannerForgotPasswordVerifyOTP.fulfilled, (state, {
            payload
        }) => {
            state.loading = false;
            if (payload.success) {
                state.message = payload.message;
                state.data = payload;
                state.status = payload.success;
                console.log(payload)
            } else {
                    state.error = Object.values(payload.error)[0]
            }
        })
        .addCase(bannerForgotPasswordVerifyOTP.rejected, (state, action) => {
            state.loading = false;
            if (action.payload.errors){
                state.error = Object.values(action.payload.errors)[0][0]
            }
            else{
                state.error = Object.values(action.payload.errors)[0][0]
            }
        });

    // banner password reset otp
                builder
                .addCase(bannerPasswordReset.pending, (state) => {
                    state.loading = true;
                })
                .addCase(bannerPasswordReset.fulfilled, (state, {
                    payload
                }) => {
                    state.loading = false;
                        state.message = payload.message;
                        state.data = payload;
                        state.status = payload.success;

                })
                .addCase(bannerPasswordReset.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload.message
                    // if (action.payload.errors){
                    //     state.error = Object.values(action.payload.errors)[0][0]
                    // }
                    // else{
                    //     state.error = Object.values(action.payload.errors)[0][0]
                    // }
                });
//   *********** Banner Registration Add-Cases End Here    ***********


// ************* Banner-AD Add Cases Start Here ************* 


    // banner Creation
    builder
    .addCase(bannerPostAd.pending, (state) => {
        state.loading = true;
    })
    .addCase(bannerPostAd.fulfilled, (state, {
        payload
    }) => {
        state.loading = false;

            state.message = payload.message;
            state.data = payload;
            state.status = payload.success;

            console.log(payload)

    })
    .addCase(bannerPostAd.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.errors
        

        const error = action.payload?.message;

        if (error && error.includes('Unauthorized')) {
            state.signout = true;
          }
    });

     // banner ad  list
        builder
        .addCase(bannerAdList.pending, (state) => {
            state.loading = true;
        })
        .addCase(bannerAdList.fulfilled, (state, {
            payload
        }) => {
            state.loading = false;
            console.log(payload)
                state.data = payload;
        })
        .addCase(bannerAdList.rejected, (state, action) => {
            state.loading = false;
            const error = action.payload?.message;
            if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
        });

     // banner ad  delete
        builder
        .addCase(bannerAdDelete.pending, (state) => {
            state.loading = true;
        })
        .addCase(bannerAdDelete.fulfilled, (state, {
            payload
        }) => {
            state.loading = false;
            console.log(payload)
            state.message = payload.message;

            
        })
        .addCase(bannerAdDelete.rejected, (state, action) => {
            state.loading = false;
            const error = action.payload?.message;
            if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
        });

     // banner ad list search
        builder
        .addCase(bannerAdListSearch.pending, (state) => {
            state.loading = true;
        })
        .addCase(bannerAdListSearch.fulfilled, (state, {
            payload
        }) => {
            state.loading = false;
            state.data = payload;   
        })
        .addCase(bannerAdListSearch.rejected, (state, action) => {
            state.loading = false;
            const error = action.payload?.message;
            if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
        });


        // ************* Banner-AD Add Cases End Here ************* 


// ************* profile setting Add Cases End Here ************* 

        // banner user details
        builder
        .addCase(bannerUserDetails.pending, (state) => {
            state.loading = true;
        })
        .addCase(bannerUserDetails.fulfilled, (state, {
            payload
        }) => {
            state.loading = false;
            console.log(payload)
            if (payload.status) {
                state.data = payload;
            }
        })
        .addCase(bannerUserDetails.rejected, (state, action) => {
            state.loading = false;
            const error = action.payload?.message;
            if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
        });

        // banner uodate details
        builder
        .addCase(bannerUserDetailsUpdate.pending, (state) => {
            state.loading = true;
        })
        .addCase(bannerUserDetailsUpdate.fulfilled, (state, {
            payload
        }) => {
            state.loading = false;
                state.message = payload.message;
        })
        .addCase(bannerUserDetailsUpdate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload.errors;
            console.log(action.payload.errors)
            const error = action.payload?.message;
            if (error && error.includes('Unauthorized')) {
                state.signout = true;
              }
        });

                    // countries
                    builder.addCase(countriesList.pending, (state) => {
                        // state.loading = true;
                      });
                      builder.addCase(countriesList.fulfilled, (state, action) => {
                        // state.loading = false;
                        state.country_list = action.payload;

                      });
                      builder.addCase(countriesList.rejected, (state, action) => {
                        const error=action?.payload?.error
                        if(error && error.includes('Unauthorized')){
                          state.signout=true
                        }
                        
                        // state.loading = false;
                        state.error = action?.payload?.errors;

                      });
                                  // states by country
            builder.addCase(statesByCountry.pending, (state) => {
                // state.loading = true;
              });
              builder.addCase(statesByCountry.fulfilled, (state, action) => {
                // state.loading = false;
                state.states_list = action.payload;
 
              });
              builder.addCase(statesByCountry.rejected, (state, action) => {
                const error=action?.payload?.error
                if(error && error.includes('Unauthorized')){
                  state.signout=true
                }
                
                // state.loading = false;
                state.error = action?.payload?.errors;

              });
        // cities by states
            builder.addCase(citiesByStates.pending, (state) => {
                // state.loading = true;
              });
              builder.addCase(citiesByStates.fulfilled, (state, action) => {
                // state.loading = false;
                state.cities_list = action.payload;

              });
              builder.addCase(citiesByStates.rejected, (state, action) => {
                const error=action?.payload?.error
                if(error && error.includes('Unauthorized')){
                  state.signout=true
                }
                
                // state.loading = false;
                state.error = action?.payload?.errors;

              });
        // cities by country
            builder.addCase(citiesByCountry.pending, (state) => {
                // state.loading = true;
              });
              builder.addCase(citiesByCountry.fulfilled, (state, action) => {
                // state.loading = false;
                state.cities_list = action.payload;

              });
              builder.addCase(citiesByCountry.rejected, (state, action) => {
                const error=action?.payload?.error
                if(error && error.includes('Unauthorized')){
                  state.signout=true
                }
                
                // state.loading = false;
                state.error = action?.payload?.errors;

              });

    //categories list
    builder.addCase(categoriesList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(categoriesList.fulfilled, (state, action) => {
        state.loading = false;
        state.mainCategories = action.payload;
      });
      builder.addCase(categoriesList.rejected, (state, action) => {
        const error = action.payload?.error;
  
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.error = action.payload;
      });
    //sub-categories list
    builder.addCase(subCategoriesList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(subCategoriesList.fulfilled, (state, action) => {
        state.loading = false;
        state.subCategories = action.payload;
      });
      builder.addCase(subCategoriesList.rejected, (state, action) => {
        const error = action.payload?.error;
  
        if (error && error.includes("Unauthorized")) {
          state.signout = true;
        }
        state.loading = false;
        state.error = action.payload;
      });

// ************* profile setting Add Cases End Here ************* 


    },

})

export const {
    clearMessage,
    resetSignout,
    signoutUser,
} = bannerSlice.actions;
export default bannerSlice.reducer;