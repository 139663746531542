import React from "react";
import BannerLayout from "../../banner-dashboard-layout/BannerLayout";
import BannerDashboardStats from "../../components/banner-dashboard-Stats/BannerDashboardStats";

export default function BannerDashboardStatsPage() {
  return (
    <div>
      <BannerLayout>
        <BannerDashboardStats />
      </BannerLayout>
    </div>
  );
}
