import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { bannerLogin, clearMessage, resetSignout } from "../store/bannerSlice";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";

export default function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, message, error, status, role, token } = useSelector(
    (state) => state.banner
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleSubmit = () => {
    if (email === "" || password === "") {
      toast.error("Please Fill All the Fields ...");
    } else {
      dispatch(bannerLogin({ email, password }));
    }
  };

  useEffect(() => {
    if (!loading) {
      if (status) {
        toast.success(message);
        if (role === "3" && token) {
          navigate("/banner-dashboard");
        }
      }
      if (error) {
        toast.error(error);
      }
      dispatch(clearMessage());
    }

    dispatch(resetSignout());
  }, [message, error, status]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  // for password visibility
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

    // if someone come to the login page we are clearing it existing token and other details
    useEffect(() => {
      localStorage.removeItem("role");
      localStorage.removeItem("token");
    }, []);

  return (
    <div className=" h-[100vh] flex justify-center items-center px-4 md:px-0 bg-[#FFFAF4]">
      <div
        className="w-full md:w-fit p-4 md:p-14 bg-white flex flex-col items-center rounded-3xl"
        style={{ boxShadow: "0px 5px 30px 0px #00000008" }}
      >
        <img className="mx-auto" src="/assets/images/login-logo.svg" alt="" />
        <div className="font-bold tracking-wide text-xl md:text-3xl mt-5 mb-2 text-center">
          Login to Banner Dashboard
        </div>
        <div className="text-xs md:text-sm text-center font-bold tracking-wide">
          Login to your Wizalot Banner Dashboard!
        </div>
        <div className="w-full md:w-[30rem] mt-6">
          <label className="form-control ">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Email Address
              </span>
            </div>
            <input
              type="email"
              placeholder="mike.tyson@mail.com"
              className="p-3 md:p-4 rounded-lg text-sm border border-[#EBF0ED] bg-[#FAFAFA] w-full focus:outline-none focus:border-[#EBF0ED]"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label className="form-control mt-3">
            <div className="label">
              <span className="text-xs md:text-base font-bold tracking-wide">
                Enter Your Password
              </span>
            </div>
            <span className="flex items-center relative">
              <input
                type={passwordVisible ? "text" : "password"}
                placeholder="********"
                className="p-3 md:p-4 rounded-lg text-sm border border-[#EBF0ED] bg-[#FAFAFA] w-full focus:outline-none focus:border-[#EBF0ED]"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {passwordVisible ? (
                <IoEyeOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <IoEyeOffOutline
                  className="absolute right-4 cursor-pointer"
                  onClick={togglePasswordVisibility}
                />
              )}
            </span>
          </label>
          <div className="flex justify-end mt-2">
            <Link
              className="text-[#C19A6B] text-sm md:text-base"
              to="/forgot-password"
            >
              Forgot Password?
            </Link>
          </div>
          <button
            disabled={loading}
            onClick={handleSubmit}
            className="bg-[#C19A6B] w-full rounded-lg py-3 mt-[1rem] text-white font-bold tracking-wider"
          >
            {!loading ? "Login" : "Loading ..."}
          </button>
          <div className=" mt-3 flex justify-center items-center gap-1">
            <span className="font-[400] text-sm">
              Want to register your account?
            </span>
            <Link
              to="/profile-creation"
              className="text-[#C19A6B] text-[15px] font-semibold"
            >
              Sign Up
            </Link>
          </div>
        </div>
        {/* <div className="text-[#C19A6B] text-end mt-3 font-bold tracking-wide cursor-pointer">
          Forgot Password?
        </div> */}
      </div>
    </div>
  );
}
