import BannerLayout from "../../banner-dashboard-layout/BannerLayout";
import BannerAdsList from "../../components/Banner-Ads/BannerAdsList";

export default function BannerAdsListPage() {
  return (
    <BannerLayout>
      <BannerAdsList />
    </BannerLayout>
  );
}
