import React from "react";
import Root from "../pages/Root";
import authRoutes from "./authRoutes";
import proufileCreationRoutes from "./proufileCreationRoutes";
import bannerDashboardRoutes from "./bannerDashboardStatsRoutes";
import accountAndsettingsRoute from "./accountAndsettingsRoute";
import bannerAdsRoutes from "./bannerAdsRoutes";
import ProtectedRoute from "../ProtectedRoute";
import chatRoute from "./chatRoutes";

const mainRoutes = [
  {
    path: "/",
    element: <Root />,
    children: [
      ...authRoutes,
      ...proufileCreationRoutes,
      {

        path: "/banner-dashboard",
        element: <ProtectedRoute />,
        children: [
          ...bannerDashboardRoutes,
          ...accountAndsettingsRoute,
          ...bannerAdsRoutes,
          ...chatRoute,
        ],
      },
    ],
  },
];
export default mainRoutes;
