import AccountAndSettingsPage from "../pages/AccountAndSettingsPage";

const accountAndsettingsRoute = [
  {
    path: "/banner-dashboard/account-settings",
    element: <AccountAndSettingsPage />,
  },
];

export default accountAndsettingsRoute;
