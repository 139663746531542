import React from "react";
import { RxCross2 } from "react-icons/rx";
import "./modal.css";

export default function PaymentModal() {
  return (
    <>
      {/* Open the modal using document.getElementById('ID').showModal() method */}
      <dialog id="my_modal_2" className="modal ">
        <div className="modal-box max-w-[33rem]">
          <button
            // onClick={() => document.getElementById("my_modal_2").close()}
            className="absolute top-1 right-1 shadow-sm rounded-full  p-2"
          >
            <RxCross2 className="w-6 h-6" />
          </button>
          {/* shipping detail */}
          <div className="mt-8 w-full md:w-[30rem] rounded-md md:px-5 py-2 ">
            {/* cardholder name */}
            <div className="relative mt-4">
              <label
                htmlFor="cardholder-name-input"
                className="absolute left-2 -top-2 px-1 bg-white text-xs  text-[#C19A6B]"
              >
                CARDHOLDER NAME
              </label>
              <input
                type="text"
                id="cardholder-name-input"
                name="cardholder-name"
                className="border p-2 pl-8 w-full rounded-md  focus:outline-none text-[#C19A6B]"
              />
            </div>

            {/* card number input */}
            <div className="relative mt-4">
              <label
                htmlFor="card-number-input"
                className="absolute left-2 -top-2 px-1 text-xs bg-white  text-[#C19A6B]"
              >
                CARD NUMBER
              </label>
              <input
                type="text"
                id="card-number-input"
                name="card-number"
                className="border p-2 pl-8 w-full rounded-md  focus:outline-none text-[#C19A6B]"
              />
            </div>

            {/* expiry date + cvv */}
            <div className="flex gap-3">
              <div className="relative mt-4">
                <label
                  htmlFor="expiry-input"
                  className="absolute left-2 -top-2 px-1 text-xs bg-white  text-[#C19A6B]"
                >
                  EXPIRY DATE
                </label>
                <input
                  type="text"
                  id="expiry-input"
                  name="expiry-date"
                  className="border p-2 pl-8 w-full rounded-md  focus:outline-none text-[#C19A6B]"
                />
              </div>
              <div className="relative mt-4">
                <label
                  htmlFor="cvv-input"
                  className="absolute left-2 -top-2 bg-white px-1 text-xs  text-[#C19A6B]"
                >
                  CVV
                </label>
                <input
                  type="text"
                  id="cvv-input"
                  name="cvv"
                  className="border p-2 pl-8 w-full rounded-md  focus:outline-none text-[#C19A6B]"
                />
              </div>
            </div>
          </div>

          {/* Total order detail */}
          <div className={`animate-card z-20  `}>
            <div className="mt-6 w-full md:w-[30rem] rounded-md px-5 py-2 order-summary-upper-card bg-[#FFFFFF] border border-gray-50 shadow-sm   box-1">
              <div className="flex justify-between my-3">
                <div className=" font-[700] tracking-wide">Total Payment</div>
                <div className="font-[700]">$520.00</div>
              </div>
            </div>

            <div className="w-full md:w-[30rem] -mt-2  py-3 rounded-md order-summary-lower-part bg-[#FFFFFF] border border-gray-50 shadow-sm box-2">
              {/* dotted line */}
              <div className="border border-dashed border-[#C19A6B] opacity-40 mt-[-0.7rem]"></div>

              <div className="px-5 mt-8 mb-2 flex justify-center">
                <button className="bg-[#C19A6B] py-3 px-[8rem] md:px-[10rem] text-nowrap rounded text-white font-[500] text-sm md:text-xl tracking-wider">
                  Pay Now
                </button>
              </div>
            </div>
          </div>
        </div>
        <form method="dialog" className="modal-backdrop">
          <button>close</button>
        </form>
      </dialog>
    </>
  );
}
