import BannerAdsListPage from "../pages/Banner-Ads-Pages/BannerAdsListPage";
import BannerStatsPage from "../pages/Banner-Ads-Pages/BannerStatsPage";
import PostBannerAdPage from "../pages/Banner-Ads-Pages/PostBannerAdPage";

const bannerAdsRoutes = [
  {
    path: "/banner-dashboard/banner-ads",
    children: [
      {
        index: true,
        element: <BannerAdsListPage />,
      },

      {
        path: "/banner-dashboard/banner-ads/banner-stats",
        element: <BannerStatsPage />,
      },
    ],
  },
  {
    path: "/banner-dashboard/post-banner-ads",
    element: <PostBannerAdPage />,
  },
];

export default bannerAdsRoutes;
