import BreadCrums from "../Common/BreadCrums";

export default function BannerStats() {
  return (
    <div className="xl:px-4 flex flex-col gap-4">
      <BreadCrums
        breadCrum={[
          {
            name: "Banner Ad",
            path: "/banner-dashboard/banner-ads",
          },
          {
            name: "Banner Ad List",
            path: "/banner-dashboard/banner-ads",
          },
          {
            name: "Banner Stats",
            path: "/banner-dashboard/banner-ads/banner-stats",
          },
        ]}
      />

      <div className="flex gap-4">
        <div className="flex flex-col gap-6 text-left p-4 md:p-7 shadow-lg md:shadow-sm rounded-lg w-full md:min-w-52 max-w-full">
          <span className="font-semibold text-center md:text-start text-base md:text-xl">
            Total Impressions
          </span>
          <span className="font-bold text-center md:text-start text-[50px] md:text-[76px] leading-[4rem]">
            63
          </span>
        </div>

        <div className="flex flex-col gap-6 text-left p-4 md:p-7 shadow-lg md:shadow-sm rounded-lg w-full md:min-w-52 max-w-full">
          <span className="font-semibold text-center md:text-start  text-base md:text-xl">
            Total Clicks
          </span>
          <span className="font-bold text-center md:text-start text-[50px] md:text-[76px] leading-[4rem]">
            60
          </span>
        </div>
      </div>
    </div>
  );
}
