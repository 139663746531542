export const BANNER_REGISTER = "banner/register";
export const BANNER_VERIFY_OTP = "banner/verify-otp";
export const BANNER_LOGIN = "banner/login";
export const BANNER_FORGOT_PASSWORD = "banner/password-forgot";
export const BANNER_FORGOT_PASSWORD_VERIFY_OTP = "banner-password-verify-otp"
export const BANNER_PASSWORD_RESET = "banner/password-reset"
export const BANNER_AD_POST = "banner/banner-create";
export const BANNER_AD_LISTS = "banner/list"
export const BANNER_AD_DELETE = "banner/delete/"
export const BANNER_AD_LISTS_SEARCH = "banner/search"
export const BANNER_AD_LIST = "banner/list"
export const BANNER_USER_DETAILS = "/banner/profile"
export const BANNER_UPDATE_USER_DETAILS = "banner/banner-booster-update"

// address
export const COUNTRY_LIST = "/countries"
export const STATES_BY_COUNTRY = "/countries/"
export const CITIES_BY_STATES = "/countries/state/"
export const CITIES_BY_COUNTRY = "countries/cities/"

