import BannerImpressionCharts from "../Graph/BannerImpressionChart";
import BannerClicksCharts from "../Graph/BannerClicksChart";
import BreadCrums from "../Common/BreadCrums";
export default function BannerDashboardStats() {
  return (
    <>
      <div className="md:px-4 flex flex-col gap-4 ">
        <BreadCrums
          breadCrum={[
            {
              name: "Banner Dashboard",
            },
          ]}
        />

        {/* graphs */}
        <div className="flex flex-col md:flex-row gap-5 items-center">
          <BannerImpressionCharts />
          <BannerClicksCharts />
        </div>
      </div>
    </>
  );
}
