import React from "react";


const DeletePopup = ({ isVisible, onDeleteConfirmation, onDeleteCancel }) => {
  const handleClose = () => {
    onDeleteCancel();
  };

  return isVisible ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-md px-8 py-5 w-full max-w-md">
        <h2 className="text-lg font-bold tracking-wider text-start mb-4">Delete?</h2>
        <p className="text-base text-gray-700 mb-6">
        The selected items will be deleted
        </p>
        <div className="flex justify-center gap-5">
          <button className="px-16 py-4 tracking-wide rounded-xl bg-white border border-[#828282] text-[#c0afaf] font-medium focus:outline-none" onClick={handleClose}>Cancel</button>
          <button
            className="px-16 py-4 rounded-xl tracking-wide bg-[#C19A6B] hover:bg-[#c2a37d] text-white font-medium focus:outline-none"
            onClick={onDeleteConfirmation}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  ) : null; // Render nothing if not visible
};

export default DeletePopup;
