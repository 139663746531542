import React from "react";
import AccountAndSettings from "../components/AccountAndSettings";
import BannerLayout from "../banner-dashboard-layout/BannerLayout";

export default function AccountAndSettingsPage() {
  return (
    <div>
      <BannerLayout>
        <AccountAndSettings />
      </BannerLayout>
    </div>
  );
}
